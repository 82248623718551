<template>
  <BaseCard
    :resource="$data.$constants.RESOURCES.RESOURCE_USERS"
    actions-visible
    :footer-visible="false"
    @edit-icon-click="$router.push({ name: 'editUser', params: { id: $route.params.id }})"
    @delete-confirmation="$emit('delete-user')"
  >
    <template #title>
      <b-avatar
        size="lg"
        :src="avatar"
        class="mr-1"
      />
      <span class="text-capitalize text-dark">{{ userFullName }}</span>
    </template>
    <hr class="m-0">
    <ProfilePanel :profile="userProfile" />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'

export default {
  name: 'UserProfileCard',
  components: { ProfilePanel, BaseCard },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    userFullName() {
      if (!this.user) {
        return ''
      }

      return `${this.user.name} ${this.user.surname || ''}`
    },
    avatar() {
      return this.user?.avatar || ''
    },
    rol() {
      if (!this.user || !this.user.roles || this.user.roles.length === 0) {
        return 'Sin rol'
      }

      return this.user.roles[0].display_name
    },
    userProfile() {
      return [
        { label: 'Correo electrónico', value: this.user?.email || 'Sin correo electrónico' },
        { label: 'Rol', value: this.rol },
        { label: 'Cargo', value: this.user?.job || 'Sin cargo' },
        { label: 'Teléfono', value: this.user?.phone || 'Sin teléfono' },
        { label: 'Departamento', value: this.user?.department || 'Sin departamento' },
      ]
    },
  },
}
</script>

<style scoped>

</style>
