<template>
  <UserProfileCard
    :user="user"
    @delete-user="handleDeleteUser"
  />
</template>

<script>
import { mapActions } from 'vuex'
import UsersApi from '@/api/users-api'
import UserProfileCard from '@/components/users/card/UserProfileCard.vue'

export default {
  components: { UserProfileCard },
  data() {
    return {
      user: null,
    }
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async loadUser() {
      this.setLoading(true)
      try {
        const response = await UsersApi.get(this.$route.params.id)
        this.user = response.data
      } finally {
        this.setLoading(false)
      }
    },
    async handleDeleteUser() {
      if (!this.user) {
        return
      }

      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${this.user.name || ''}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.setLoading(true)
      try {
        await UsersApi.delete(this.$route.params.id)
        await this.$router.push({ name: 'users' })
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>
