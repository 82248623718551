import Vue from 'vue'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_USERS } from '@/shared/constants/resources'

class UsersApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  create(data, notificationsVisible = true) {
    return super.create(data, this.resource, notificationsVisible)
  }

  edit(id, data, notificationsVisible = true) {
    return super.edit(id, data, this.resource, notificationsVisible)
  }

  deleteAvatar(userId) {
    return Vue.prototype.$http.delete(`${this.resource}/delete_image/${userId}`)
  }
}

export default new UsersApi(RESOURCE_USERS)
